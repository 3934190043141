import React,{Fragment, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import SearchFilters from "../components/ResultList/SearchFilters/SearchFilters"
import ResultView from "../components/ResultList/ResultView/ResultView"

import "../scss/custom.scss";

const SearchResults  = () => {

	return (
		<>
			<HeaderTwo />
			<div className="content result-list-content">
	        	<SearchFilters />
	        	<ResultView />
	    	</div>
			<Footer />
		</>
	)
}


export default SearchResults;