import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react";
import { Container } from 'react-bootstrap';
import PropertyCard from "../PropertyCard/PropertyCard"
import GetRegister from"../GetRegister/GetRegister"
import mobMap from "../../../images/mob-map-img.jpg"
import tabMap from "../../../images/tab-map-img.jpg"
import deskMap from "../../../images/desk-map-img.jpg"
import  "./ResultView.scss";

  const ResultView=()=> {

    const [addList, setaddList] = useState(false);
    const [addMap, setaddMap] = useState(false);
    const [scroll, setScroll] = useState(false);


    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 210)
      })
    }, [])

    const addingList = () =>{
      setaddList(!addList)
      setaddMap(false)
    }

    const addingMap = () =>{
      setaddMap(true)
    }
    
    return(

  <section className={`result-map ${addMap ? "results-view map-veiw-content" : addList ? "results-view" : "results-view"}` }>
    <div className="list-view-wrap">
        <Container>
            <div className ="results-detail">
                <div className="result-head">
                    <div className="d-md-flex justify-content-between">
                        <div className="result-info">
                            <h4><strong>48</strong> Properties for sale in London <i className="icon-finder"></i> </h4>
                        </div>
                        <ul className="view-list d-md-none">
                            <li><Link to="#">Highest Price First </Link></li>
                            <li className="list-ctrl"><Link to="#" onClick={e=>addingList()}> Grid View</Link></li>
                            <li className="map-ctrl"><Link to="#" onClick={e=>addingMap()}>Map View</Link></li>
                        </ul>
                        <ul className="view-list d-none d-md-block">
                            <li><span className="label-txt d-xl-none">Sort by:</span><Link to="#" className="sort-by">Highest <span className="d-md-none d-xl-inline"> Price First</span> <i className="icon-arrow-dropdown"></i></Link></li>
                            <li className="list-ctrl"><Link to="#" onClick={e=>addingList()}><i className="icon-list"></i> <span className="d-none d-xl-inline">List</span> </Link></li>
                            <li className="map-ctrl"><Link to="#" onClick={e=>addingMap()}><i className="icon-map-active"></i> <span className="d-none d-xl-inline">Map</span></Link></li>
                        </ul>
                    </div>
                    <p className="desc d-none d-md-block">Discover a wide range of property for sale in London with Martyn Gerrard Estate Agents. To filter these results, use our filter above, or for more information about buying property in London, please contact us.</p>
                </div>
                <div className="cards-wrp">
                    <PropertyCard />
                    <GetRegister />
                    <PropertyCard />
                </div>
            </div>
        </Container>
    </div>
    <div className={scroll ? "map-scroll map-view-wrap" : "map-view-wrap"}>
        <picture>
            <source media="(min-width:1200px)" srcset={deskMap} />
            <source media="(min-width:768px)" srcset={tabMap} />
            <img loading="lazy" className="banner-img" src={mobMap} alt="map" />
        </picture>
    </div>
  </section>
)
  }
export default ResultView