import { Link } from "@StarberryUtils";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import  "./GetRegister.scss";
import BgShadowWhite from "../../../images/bg-shadow-white2.svg";
//import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import GenerateLink from "../../common/site/generate-link";



const GetRegister = (props) => {
  

  const data = useStaticQuery(graphql`
    query{
      strapiSearchResultsCrossSelling {
        Cross_Selling_1_CTA_Label
        Cross_Selling_1_Content
        Cross_Selling_2_CTA_Label
        Cross_Selling_2_Content
        Cross_Selling_3_CTA_Label
        Cross_Selling_3_Content
        Cross_Selling_4_CTA_Label
        Cross_Selling_4_Content
        Cross_Selling_1_CTA_Link {
          URL
          id
        }
        Cross_Selling_2_CTA_Link {
          URL
          id
        }
        Cross_Selling_3_CTA_Link {
          URL
          id
        }
        Cross_Selling_4_CTA_Link {
          URL
          id
        }
      }
    }
  `);

  const cross_selling = data.strapiSearchResultsCrossSelling


  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

  if(props.getstart == 1) {
    return (
      <div className="property-card get-reg-blue get-register d-lg-flex justify-content-between align-items-center">
        <ReactMarkdown source={cross_selling.Cross_Selling_1_Content} allowDangerousHtml />   
        {cross_selling.Cross_Selling_1_CTA_Link &&
        <GenerateLink link={cross_selling.Cross_Selling_1_CTA_Link} class="btn btn-default">     
          {cross_selling.Cross_Selling_1_CTA_Label}
        </GenerateLink>     
        }
      </div>
    )
  }
  else if(props.getstart == 2) {
    return (
      <div className="property-card get-register d-lg-flex justify-content-between align-items-center style-2">
        <ReactMarkdown source={cross_selling.Cross_Selling_2_Content} allowDangerousHtml />    
        {cross_selling.Cross_Selling_2_CTA_Link &&
        <GenerateLink link={cross_selling.Cross_Selling_2_CTA_Link} class="btn btn-default">     
          {cross_selling.Cross_Selling_2_CTA_Label}
        </GenerateLink>     
        }
      </div>
    )
  }
  else if(props.getstart == 3) {
    return (
      <div className="property-card get-reg-blue get-register d-lg-flex justify-content-between align-items-center">
        <ReactMarkdown source={cross_selling.Cross_Selling_3_Content} allowDangerousHtml />    
        {cross_selling.Cross_Selling_3_CTA_Link &&
        <GenerateLink link={cross_selling.Cross_Selling_3_CTA_Link} class="btn btn-default">     
          {cross_selling.Cross_Selling_3_CTA_Label}
        </GenerateLink>   
        }  
      </div>
    )
  }
  else if(props.getstart == 4) {
    return (
      <div className="property-card get-register d-lg-flex justify-content-between align-items-center style-2">
        <ReactMarkdown source={cross_selling.Cross_Selling_4_Content} allowDangerousHtml />  
        {cross_selling.Cross_Selling_4_CTA_Link &&  
        <GenerateLink link={cross_selling.Cross_Selling_4_CTA_Link} class="btn btn-default">     
          {cross_selling.Cross_Selling_4_CTA_Label}
        </GenerateLink>     
        }
      </div>
    )
  }
  else {
    return null;
  }
} 


export default GetRegister
