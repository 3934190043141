import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react";
import Slider from 'react-slick';
import mainImg01 from "../../../images/main-img01.jpg"
import mainVideo01 from "../../../images/main-video01.jpg"
import smallImg01 from "../../../images/main-secondary01.jpg"
import mainImg02 from "../../../images/main-img02.jpg"
import mainVideo02 from "../../../images/main-video02.jpg"
import smallImg02 from "../../../images/main-secondary02.jpg"
import mainImg03 from "../../../images/main-img03.jpg"
import mainVideo03 from "../../../images/main-video03.jpg"
import smallImg03 from "../../../images/main-secondary03.jpg"
import mainImg04 from "../../../images/main-img04.jpg"
import mainVideo04 from "../../../images/main-video04.jpg"
import smallImg04 from "../../../images/main-secondary04.jpg"
//import ScrollAnimation from 'react-animate-on-scroll';
import  "./PropertyCard.scss";

  const PropertyCard = (props) => {

    const propOptions = [
        { price: "3,000,000", addLabel: "Edmunds Walk, East Finchley, London, N2", mainImg: mainImg01, mainVideo: mainVideo01, smallImg: smallImg01},
        { price: "5,000,000", addLabel: "Cedars Close, Hendon, London, NW4", mainImg: mainImg02, mainVideo: mainVideo02, smallImg: smallImg02},
        { price: "1,950,000", addLabel: "Parkway, Southgate, London, N14", mainImg: mainImg03, mainVideo: mainVideo03, smallImg: smallImg03},
        { price: "1,950,000", addLabel: "Milton Park, Highgate, London, N6", mainImg: mainImg04, mainVideo: mainVideo04, smallImg: smallImg04}
    ];

    const addingClass = (e) =>{
        let lexicalE = e.persist();
                let dm = document.getElementsByClassName("active-map")
                //console.log("testing",dm)
                if(dm.length ==0 ) {
                 if(document.querySelector('.active-map')!==null){
                    document.querySelector('.active-map').classList.remove('active-map')
                    e.target.classList.add("active-map")                    
            }
                    e.target.classList.add("active-map")
                    
                } else {

               document.querySelector('.active-map').classList.remove('active-map')
        }
            //console.log("test",e.target.className ,dm)
    }

    const settings = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst:true,
        responsive: [
          {
            breakpoint: 767,
             settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    arrows: true,
                }
          },
          {
              breakpoint: 9999,
              settings: "unslick"
          }
          
      ]
      }
    return(<React.Fragment>
        {propOptions.map((item, index) =><div className="property-card d-md-flex flex-wrap"  key={index}>
            <div className="view-part1">
                {/*<ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut'>*/}
                <Slider className="main-slider" {...settings} >
                    <div className="main-item">
                        <img loading="lazy" src={item.mainImg} alt="Image" />
                        <div className="on-map d-flex">
                            <Link to="#" onClick={e=>addingClass(e)}><i className="icon-location"></i> <span className="exp-txt text-uppercase">View on Map</span></Link>
                            <Link to="#"><i className="icon-map-view"></i> <span className="exp-txt text-uppercase">View Floorplan</span></Link>
                        </div>
                    </div>
                    <div className="main-item d-md-none">
                        <img loading="lazy" src={item.mainVideo} alt="Image" />
                        <Link className="play-btn" to="#"><i className="icon-play-icon"></i></Link>
                    </div>
                    <div className="main-item d-md-none">
                        <img loading="lazy" src={item.smallImg} alt="Image" />
                    </div>
                </Slider>
                {/*</ScrollAnimation>*/}
            </div>
            <div className="view-part2 d-none d-md-block">
            {/*<ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut'>*/}
                <div className="main-item">
                    <img loading="lazy" src={item.mainVideo} alt="Image" />
                    <Link className="play-btn" to="#"><i className="icon-play-icon"></i></Link>
                </div>
                <div className="main-item">
                    <img loading="lazy" src={item.smallImg} alt="Image" />
                </div>
                {/*</ScrollAnimation>*/}
            </div>
            <div className="card-details">
            {/*<ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut'>*/}
                <div className="detail-head d-flex align-items-center justify-content-between">
                    <h2 className="price">&pound;{item.price}</h2>
                    <Link className="wishlist-icn" to="#"><i className="icon-heart"></i></Link>
                </div> 
                <span className="address-title d-block">{item.addLabel}</span>
                <span className="prop-title d-block">4 bedroom detached house for sale</span>
                <p className="prop-desc d-none d-md-block">This house is a classic example of a twentieth-century late Arts and Craft Tudor style Semi-detached family home. Internally it has been well looked after by the current owners but...</p>
                <div className="d-flex">
                    <span className="distance d-flex align-items-center"><i className="icon-station"></i> 0.4 miles</span>
                    <span className="distance d-flex align-items-center"><i className="icon-schools"></i> 0.63 miles</span>
                </div>
                {/*</ScrollAnimation>*/}
            </div>
        </div>)}

        </React.Fragment>)
  }
export default PropertyCard
