import { Link } from "@StarberryUtils";
import React,{useEffect,useState, useRef} from "react";
import {Form, Button} from 'react-bootstrap';
import  "./SearchFilters.scss";
import SelectBox from "../SelectBox/SelectBox"

const SearchFilters =(props) => {

    const [addRefine, setAddRefine] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0)
    })
  }, [])

  const textInput = useRef(null);

    const minOptions = [
        { value: '200', label: '200' },
        { value: '300', label: '300' },
        { value: '400', label: '400' },
        { value: '500', label: '500' },
      ];
      const maxOptions = [
        { value: '600', label: '600' },
        { value: '700', label: '700' },
        { value: '800', label: '800' },
        { value: '900', label: '900' },
      ];
      const bhkOptions = [
        { value: '1+ Bedrooms', label: '1+ Bedrooms' },
        { value: '2+ Bedrooms', label: '2+ Bedrooms' },
        { value: '3+ Bedrooms', label: '3+ Bedrooms' },
        { value: '4+ Bedrooms', label: '4+ Bedrooms' },
      ];
      const typeOptions = [
        { value: 'Type 1', label: 'Type 1' },
        { value: 'Type 2', label: 'Type 2' },
        { value: 'Type 3', label: 'Type 3' },
        { value: 'Type 4', label: 'Type 4' },
      ];

    //   const addingFilter = (e) =>{
    //       console.log("ee",e.target.className)
    //       if(e.target.className.includes("active")) {
    //           e.target.classList.remove("active")
    //       } else {
    //           e.target.classList.add("active")
    //       }
        
    //   }

      const outSideLink= (e) => {
          //console.log("avvaa",e.target.classList,textInput.current)
        if(!e.target.className.includes("filter-dropdown") && !e.target.className.includes('custom-control-label') && !e.target.className.includes('mb-3') && !e.target.className.includes('custom-control-input') && !e.target.className.includes('custom-control custom-checkbox custom-control-inline') && !e.target.className.includes('offer-list d-flex flex-wrap') && !e.target.className.includes('dropdown-exp')) {
            // document.querySelector(".dropdown-exp").classList.remove("active")
            //console.log("tests")
            setShowFilter(showFilter)
        }
    }


      useEffect(()=>{
        document.addEventListener('click',outSideLink)
        // return document.removeEventListener('click',outSideLink)
      },[])


      const display=()=>{
        setShowFilter(!showFilter)
      }

      const addingRefine=()=>{
        setAddRefine(!addRefine)
      }


    return (

  <div className={scroll ? "active-scroll search-filters" : "search-filters"} >
    <Link className="filter-toggle d-flex justify-content-between align-items-center d-xl-none text-uppercase" to="#"
     onClick={addingRefine} >
        <span>refine your results</span>
        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.98403 8.00005L0.960028 15.04C0.752028 15.248 0.400028 15.248 0.176028 15.04C-0.0319716 14.832 -0.0319716 14.48 0.176028 14.256L6.43203 8.00005L0.160028 1.72805C-0.0479717 1.52005 -0.0479717 1.16805 0.160028 0.944047C0.368028 0.736047 0.720028 0.736047 0.944028 0.944047L7.98403 8.00005Z" fill="#ffffff"/>
        </svg>
    </Link>
    <Form  className={addRefine ? "active-filter filters-form d-xl-flex justify-content-between" : "filters-form d-xl-flex justify-content-between"}>
        <Form.Group className="radios-wrp">
        {['radio'].map((type) => (
            <div key={`default-${type}`} className="radio-boxes d-flex">
            <Form.Check 
                type={type}
                id={`default1-${type}`}
                label={`buy`}
                name={'radioslt'}
            />
            <Form.Check 
                type={type}
                id={`default2-${type}`}
                label={`rent`}
                name={'radioslt'}
            />
            </div>
        ))}
        </Form.Group>
        <Form.Group className="street-addr d-flex justify-content-between align-items-center">
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00004 0.421875C3.37023 0.421875 0.41626 3.375 0.41626 7.00566C0.41626 8.47884 0.919979 9.92756 1.83657 11.0866L7.00004 17.5781L12.1658 11.0835C13.0801 9.92756 13.5838 8.47884 13.5838 7.00566C13.5838 3.375 10.6307 0.421875 7.00004 0.421875ZM7.00004 9.45984C5.64723 9.45984 4.54585 8.35847 4.54585 7.00566C4.54585 5.652 5.64723 4.55147 7.00004 4.55147C8.3537 4.55147 9.45423 5.65228 9.45423 7.00566C9.45423 8.35847 8.3537 9.45984 7.00004 9.45984Z" fill="#575757"/>
        </svg>
            <Form.Control type="text" placeholder="Street, area or postcode" />
        </Form.Group>

        <Form.Group className="price-wrap d-flex justify-content-between align-items-center">
            <div className="price-box">
                <SelectBox
                    placeHolder="Min Price"
                    options={minOptions}
                />
            </div>
            <div className="price-box">
                <SelectBox
                    placeHolder="Max Price"
                    options={maxOptions}
                />
            </div>
        </Form.Group>
        <Form.Group className="select-grp-opt d-flex align-items-center">
                <SelectBox
                    placeHolder="3+ Bedrooms"
                    options={bhkOptions}
                />
        </Form.Group>
        <Form.Group className="select-grp-opt d-flex align-items-center">
                <SelectBox
                    placeHolder="Property Type"
                    options={typeOptions}
                />
        </Form.Group>
        <Form.Group className="select-grp-opt fil-opt d-flex align-items-center">
                {/* <SelectBox
                    placeHolder="Filters"
                /> */}
                <Link  className="filter-dropdown" to="#" onClick={display}><span>Filters </span> 
                <i class="icon-arrow-dropdown"></i></Link>
                {
                showFilter ?
                <div className="dropdown-exp" ref={textInput}>
                    {['checkbox'].map((type) => (
                        <div key={`custom-inline-${type}`}>
                            <Form.Check
                                custom
                                inline
                                label="Include Sold"
                                type={type}
                                id={`custom-inline-${type}-5`}
                            />
                            <h4>Must Include</h4>
                            <ul className="offer-list d-flex flex-wrap">
                                <li>
                                    <Form.Check
                                        custom
                                        inline
                                        label="Outside Space"
                                        type={type}
                                        id={`custom-inline-${type}-1`}
                                    />
                                </li>
                                <li>
                                    <Form.Check
                                        custom
                                        inline
                                        label="Parking"
                                        type={type}
                                        id={`custom-inline-${type}-2`}
                                    />
                                </li>
                                <li>
                                    <Form.Check
                                        custom
                                        inline
                                        label="New Home"
                                        type={type}
                                        id={`custom-inline-${type}-3`}
                                    />
                                </li>
                                <li>
                                    <Form.Check
                                        custom
                                        inline
                                        label="Chain Free"
                                        type={type}
                                        id={`custom-inline-${type}-4`}
                                    />
                                </li>
                            </ul>
                        </div>
                    ))}
                </div>
                :
                !showFilter
            }
        </Form.Group>
        </Form>
  </div>
)
    }

export default SearchFilters;
